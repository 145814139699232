<template>
  <div class="bg-main-bg wrap pb-20 flex justify-center">
    <div class="main-wrap flex flex-col items-center">
      <div
        class="header-wrap py-5 font-medium max-w-6x1 sm:flex items-center place-self-start"
      >
        <div class="text-secondary text-3x1 logo">
          <img src="/assets/images/LoanSpot-logo-alt.svg" />
        </div>
      </div>
      <div class="form-wrap mt-12">
        <div
          lass="sm:px-8 bg-white rounded-lg sm:pt-12 sm:pb-8 "
          class="max-w-xl bg-white sm:py-16 sm:px-20 sm:shadow-loan rounded-lg mx-auto sm:pt-12 sm:pb-8"
        >
          <h3 class="py-6 font-medium working--title sm:text-center">
            9. What industry do you work in currently?
          </h3>
          <form @submit.prevent="goTo" class="">
            <div class="radio-wrap">
              <svg
                class="absolute z-50 mt-4 ml-3"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.0203 10.078L13.8757 12.9327L12.9323 13.876L10.0777 11.0207C9.0155 11.8722 7.69434 12.3353 6.33301 12.3334C3.02101 12.3334 0.333008 9.64537 0.333008 6.33337C0.333008 3.02137 3.02101 0.333374 6.33301 0.333374C9.64501 0.333374 12.333 3.02137 12.333 6.33337C12.3349 7.69471 11.8718 9.01587 11.0203 10.078ZM9.68301 9.58337C10.5291 8.7133 11.0016 7.54699 10.9997 6.33337C10.9997 3.75471 8.91101 1.66671 6.33301 1.66671C3.75434 1.66671 1.66634 3.75471 1.66634 6.33337C1.66634 8.91137 3.75434 11 6.33301 11C7.54663 11.002 8.71293 10.5294 9.58301 9.68337L9.68301 9.58337Z"
                  fill="#B5B5B5"
                />
              </svg>
              <vSelect
                :options="Object.values(options)"
                class="style-chooser"
                placeholder="Search Industry"
                transition=""
              ></vSelect>
            </div>
            <div class="flex">
              <router-link
                to="/onboarding/current-residence"
                class="hidden sm:flex items-center justify-center bg-radio-bg border border-input-border rounded-lg mr-4 h-12 w-12"
              >
                <svg
                  width="11"
                  height="18"
                  viewBox="0 0 11 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.7068 16.2929L9.29257 17.7071L0.585467 9.00001L9.29257 0.292908L10.7068 1.70712L3.41389 9.00001L10.7068 16.2929Z"
                    fill="#B5B5B5"
                  />
                </svg>
              </router-link>
              <Button>
                <span id="spin" slot="spin" class="animate-spin hidden">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 0C13.6228 0 13.3171 0.305746 13.3171 0.682927V7.51219C13.3171 7.88934 13.6228 8.19512 14 8.19512C14.3772 8.19512 14.6829 7.88934 14.6829 7.51219V0.682927C14.6829 0.305746 14.3772 0 14 0ZM4.58841 3.90549C4.41362 3.90549 4.2309 3.96421 4.09756 4.09756C3.83084 4.36427 3.83084 4.80188 4.09756 5.0686L8.93139 9.89177C9.19807 10.1584 9.62503 10.1585 9.89178 9.89177C10.1585 9.62506 10.1584 9.19808 9.89178 8.9314L5.06861 4.09756C4.93527 3.96421 4.76321 3.90549 4.58841 3.90549ZM23.4116 3.90549C23.2368 3.90549 23.0648 3.96421 22.9314 4.09756L18.1082 8.9314C17.8416 9.19809 17.8416 9.62507 18.1082 9.89177C18.3749 10.1585 18.8019 10.1585 19.0686 9.89177L23.9024 5.0686C24.1692 4.80189 24.1691 4.36426 23.9024 4.09756C23.7691 3.96421 23.5863 3.90549 23.4116 3.90549ZM0.682927 13.3171C0.305746 13.3171 0 13.6228 0 14C0 14.3772 0.305746 14.6829 0.682927 14.6829H7.51219C7.88934 14.6829 8.19512 14.3772 8.19512 14C8.19512 13.6228 7.88934 13.3171 7.51219 13.3171H0.682927ZM20.4878 13.3171C20.1107 13.3171 19.8049 13.6228 19.8049 14C19.8049 14.3772 20.1107 14.6829 20.4878 14.6829H27.3171C27.6943 14.6829 28 14.3772 28 14C28 13.6228 27.6943 13.3171 27.3171 13.3171H20.4878ZM9.41158 17.9055C9.23682 17.9055 9.06473 17.9749 8.93139 18.1082L4.09756 22.9314C3.83084 23.1981 3.83088 23.6357 4.09756 23.9024C4.36428 24.1692 4.80186 24.1692 5.06861 23.9024L9.89178 19.0686C10.1584 18.8019 10.1584 18.3749 9.89178 18.1082C9.75841 17.9749 9.58635 17.9055 9.41158 17.9055ZM18.5884 17.9055C18.4136 17.9055 18.2416 17.9749 18.1082 18.1082C17.8415 18.3749 17.8416 18.8019 18.1082 19.0686L22.9314 23.9024C23.1981 24.1692 23.6358 24.1692 23.9024 23.9024C24.1692 23.6357 24.1692 23.1981 23.9024 22.9314L19.0686 18.1082C18.9353 17.9749 18.7632 17.9055 18.5884 17.9055ZM14 19.8049C13.6228 19.8049 13.3171 20.1107 13.3171 20.4878V27.3171C13.3171 27.6943 13.6228 28 14 28C14.3772 28 14.6829 27.6943 14.6829 27.3171V20.4878C14.6829 20.1107 14.3772 19.8049 14 19.8049Z"
                      fill="white"
                      fill-opacity="0.7"
                    />
                  </svg>
                </span>
                <span id="check" slot="check" class="hidden mr-1">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.00016 13.6666C3.31816 13.6666 0.333496 10.6819 0.333496 6.99992C0.333496 3.31792 3.31816 0.333252 7.00016 0.333252C10.6822 0.333252 13.6668 3.31792 13.6668 6.99992C13.6668 10.6819 10.6822 13.6666 7.00016 13.6666ZM7.00016 12.3333C8.41465 12.3333 9.77121 11.7713 10.7714 10.7712C11.7716 9.77096 12.3335 8.41441 12.3335 6.99992C12.3335 5.58543 11.7716 4.22888 10.7714 3.22868C9.77121 2.22849 8.41465 1.66659 7.00016 1.66659C5.58567 1.66659 4.22912 2.22849 3.22893 3.22868C2.22873 4.22888 1.66683 5.58543 1.66683 6.99992C1.66683 8.41441 2.22873 9.77096 3.22893 10.7712C4.22912 11.7713 5.58567 12.3333 7.00016 12.3333ZM6.3355 9.66659L3.50683 6.83792L4.4495 5.89525L6.3355 7.78125L10.1062 4.00992L11.0495 4.95259L6.3355 9.66659Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <span id="finish" slot="value"> Finish </span>
              </Button>
            </div>
            <router-link
              to="/onboarding/current-residence"
              class="back--link flex sm:hidden px-4 bg-input-bg text-textColor border border-inputBorder2 rounded-md h-12 font-medium text-sm items-center justify-center"
            >
              Back
            </router-link>
          </form>
        </div>
      </div>
      <Response
        id="response"
        wrapperClass="fixed hidden h-screen z-50 pt-40 top-0 left-0 right-0 bg-sec-bg"
        modalClass="modal-wrap mx-auto bg-white rounded-lg flex flex-col items-center justify-center"
        :modalTitle="this.responseSuccess.title"
        :modalText="this.responseSuccess.text"
      >
        <div class="mb-8" slot="modal-icon-success">
          <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="72" cy="72" r="72" fill="#27BD8B" fill-opacity="0.08"/>
          <circle cx="72" cy="72" r="56" fill="#CBEFE3"/>
          <circle cx="72" cy="72" r="40" fill="#27BD8B"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M54.5321 70.7144C53.6859 71.4244 53.5756 72.6859 54.2856 73.5321L64.8998 86.1816C65.3214 86.684 65.9374 86.9271 66.5454 86.893C66.5537 86.8925 66.5619 86.892 66.5702 86.8914C66.9784 86.8638 67.3818 86.7112 67.7192 86.4281L89.797 67.9026C90.6431 67.1926 90.7535 65.9311 90.0435 65.0849L88.8474 63.6595C88.1374 62.8133 86.8759 62.7029 86.0297 63.413L66.6091 79.7088L58.4759 70.016C57.7659 69.1698 56.5044 69.0595 55.6582 69.7695L54.5321 70.7144Z" fill="white"/>
          </svg>
        </div>
      </Response>
    </div>
  </div>
</template>

<script>
import Button from "../../components/shared/button";
import vSelect from "vue-select";
import "../../assets/scss/style.scss";
import { GET_LOAN_ROUTES } from '../../constants'
import Response from '../../components/partials/response-modal'

export default {
  name: "Industry",
  components: {
    Button,
    vSelect,
    Response
  },
  props: [],
  data() {
    return {
      options: {
        1: "Technology",
        2: "Hospitality",
        3: "Medical",
        4: "Education",
      },
      responseSuccess: {
        title: `You're all set!`,
        text: `Thanks for taking time out to finish the test.`
      }
    };
  },
  // async mounted() {
  //     await this.getAnalytics();
  // },
  methods: {
    dataSet() {
      return Object.values(this.options);
    },
    goTo(e) {
      e.preventDefault();
      document.getElementById("spin").classList.remove("hidden");
      document.getElementById("finish").classList.add("hidden");
      setTimeout(() => {
      document.getElementById('response').classList.remove('hidden')
        document.getElementById("spin").classList.add("hidden");
        document.getElementById("finish").classList.remove("hidden");
        document.getElementById("check").classList.remove("hidden");
        setTimeout(() => {
          window.location.assign(GET_LOAN_ROUTES.AMOUNT);
        }, 2000);
      }, 3000);
    },
  },
};
</script>

<style lang="css" scope>
.wrap {
  padding: 0 10% 80px;
  min-height: 100vh;
}
@media (min-width: 640px) {
  .wrap {
    
  }
}
.main-wrap {
  width: 100%;
  max-width: 1440px;
  @media (min-width: 1440px) {
    padding: 0 10%;
  }
}
.logo {
  font-family: EuclidCircularSemiB;
  font-size: 1.5rem;
}
.form-wrap {
  width: 100%;
  max-width: 550px;
}
/* .form-wrap::after {
      // display: none;
    } */
@media (min-width: 640px) {
  .form-wrap::after {
    content: "";
    display: block;
    height: 8px;
    width: 100%;
    background: #1656C1;
    border-radius: 0 0 8px 8px;
    margin-top: -8px;
  }
}
.working--title {
  font-family: SohneHalbfett, sans-serif;
  font-size: 1.5rem;
}

.radio-wrap label {
  font-family: SohneLeicht, sans-serif;
    font-size: 0.875rem;
}
.back--link {
  font-family: EuclidCircularReg;
}
.style-chooser .vs__dropdown-toggle {
  height: 48px;
  padding: 0 0 0 32px;
  background: #fcfcfc;
  margin-bottom: 32px;
  cursor: pointer;
}
.style-chooser .vs__dropdown-menu {
  background: #f7f8fb;
  font-family: EuclidCircularReg;
  /* margin-top: 16px; */
}
.style-chooser li {
  background: transparent;
  color: #b5b5b5;
  padding: 8px 16px;
  font-size: 0.875rem;
}
.style-chooser li:hover {
  background: transparent;
}
.style-chooser .vs__selected {
  font-family: EuclidCircularReg;
  font-size: 0.875rem;
  padding: 0;
  margin: 0;
}
.style-chooser .vs__search {
  font-family: EuclidCircularReg;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0;
  margin: 0 0 0 8px;
}
.style-chooser .vs__search::placeholder {
  color: #949494;
  font-family: EuclidCircularReg;
  font-size: 0.875rem;
}
.style-chooser .vs__open-indicator {
  display: none;
}
.modal-wrap {
  width: 318px;
  height: 328px;
}
</style>